.paginations .rc-pagination-options {
    display: none;
}
.paginations .rc-pagination-prev,
.paginations .rc-pagination-next,
.paginations .rc-pagination-jump-prev,
.paginations .rc-pagination-jump-next {
    margin-right: 0;
    margin-left: 10px;
    width: 30px;
    height: 32.7px;
    background: #EDEDED;
    color: #000000;
    border: 0;
}

.paginations .rc-pagination-prev .prevIcon {
    background-image: url('~/assets/images/prev.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
    height: 32.7px;
}
.paginations .rc-pagination-next .nextIcon {
    background-image: url('~/assets/images/next.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
    height: 32.7px;
}
.paginations .rc-pagination-prev:hover .prevIcon {
    background-image: url('~/assets/images/prevH.png');
}
.paginations .rc-pagination-next:hover .nextIcon {
    background-image: url('~/assets/images/nextH.png');
}
.paginations .rc-pagination-jump-next .jumpNextIcon,
.paginations .rc-pagination-jump-prev .jumpPrevIcon {
    background-image: url('~/assets/images/pagemore.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 30px;
    height: 32.7px;
}
.paginations .rc-pagination-jump-next:hover .jumpNextIcon,
.paginations .rc-pagination-jump-prev:hover .jumpPrevIcon {
    background-image: url('~/assets/images/pageMoreH.png');
}
.paginations .rc-pagination-item {
    width: 30px;
    height: 32.7px;
    background: #EDEDED;
    color: #000000;
    border-radius: 2px;
    margin-left: 10px;
    margin-right: 0;
    border: 0;
}
.paginations .rc-pagination-item a {
    padding: 0;
    text-align: center;
    width: 30px;
    height: 32.7px;
    line-height: 32.7px;
    color: #000000;
    border: 0;
}
.paginations .rc-pagination-item:focus,
.paginations .rc-pagination-item:focus a {
    background: #002C5E;
    color: #ffffff;
}
.paginations .rc-pagination-item-active,
.paginations .rc-pagination-item-active a,
.paginations .rc-pagination-item:hover,
.paginations .rc-pagination-item:hover a  {
    background: #002C5E;
    border: 0;
    color: #ffffff;
}
