.login-page {
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
  min-height: 500px;
}
.login-page .background-wrapper {
  position: relative;
  height: 100%;
  background-size: cover;
  background-position-x: center;
}

.login-page .login-form-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page .login-form-wrapper .login-form-intro {
  text-align: right;
  color: #ffffff;
  font-size: 36px;
  font-weight: 500;
  margin-right: 40px;
}

.login-page .login-form-wrapper .login-form {
  width: 383px;
  background: #ffffff;
  border-radius: 10px;
}

.login-page .login-form-wrapper .login-form .login-form-content {
  padding: 20px;
}

.login-page .login-form-wrapper .login-form .login-form-title {
  color: #002c5e;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.login-page .login-form-input-group.hasError {
  margin-bottom: 9.5px;
}

.login-page .login-form-input-group {
  margin-bottom: 30.5px;
}
.login-page .login-form-input-icon {
  height: 56px;
  margin-right: 10px;
}

.login-page .login-form-input {
  border-radius: 4px;
  border: 1px solid #8b8b8b;
  height: 56px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #002c5e;
}

.login-page .login-form-input::placeholder {
  font-size: 14px;
  color: #8b8b8b;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
}

.login-page .login-form-input:focus {
  border: 1px solid #002c5e;
  box-shadow: none;
}

.login-page .login-form-input[aria-invalid*='true'] {
  border: none;
}

.login-page .login-form-input[aria-invalid*='true']:focus {
  border: 1px solid #002c5e;
}

.login-page .login-form-input[aria-invalid='true'] {
  border: 1px solid #ff6770;
  box-shadow: none;
}

.css-gfghaq {
  margin-top: 0;
}

.login-page .login-form-submit {
  height: 56px;
  width: 100%;
  background: #002c5e;
  color: #fafafa;
  margin-bottom: 16px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.login-page .login-form-submit:hover {
  background-color: #002c5e80;
}

.login-page .login-form-submit:focus {
  box-shadow: none;
}

.login-page .login-form-submit:active {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #002c5e;
}

.login-page .login-form-wrapper .login-form .login-form-actions {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #8b8b8b;
  margin-bottom: 16px;
}

.login-page .login-form-wrapper .login-form .chakra-checkbox__control {
  border: 1px solid #8b8b8b;
}

.chakra-checkbox__control[data-checked] {
  background-color: #002c5e;
  border-color: #002c5e;
  color: #ffffff;
}

.chakra-checkbox__control[data-focus],
.chakra-checkbox__control:focus {
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
}

.chakra-checkbox__label {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4a4a4a;
}

.login-form-forgot-password {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4a4a4a;
}

.login-form-forgot-password:hover {
  text-decoration: none;
}

.login-page .login-form-wrapper .login-form .login-form-register {
  width: 100%;
  height: 56px;
  background: #fff;
  color: #002c5e;
  font-weight: 400;
  border: 1px solid #002c5e;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.login-page .login-form-wrapper .login-form .login-form-register:hover {
  border: 1px solid transparent;
  color: #ffffff;
  background: rgba(0, 44, 94, 0.5);
}

.login-page .login-form-wrapper .login-form .login-form-register:focus {
  box-shadow: none;
}

.login-page .login-form-wrapper .login-form .login-form-service-term {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #333333;
  margin-top: 15px;
}

#chakra-modal-login-page-foget-password-modal {
  padding: 20px 0;
  box-sizing: border-box;
  background-color: #fff;
}

#chakra-modal-login-page-foget-password-modal .chakra-modal__header .title-icon {
  height: 36px;
  background-repeat: no-repeat;
  background-size: 36px;
  background-position: center;
}

#chakra-modal-login-page-foget-password-modal .chakra-modal__header .title-text {
  margin-top: 8px;
  text-align: center;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #002c5e;
}

#chakra-modal-login-page-foget-password-modal .chakra-modal__close-btn {
  opacity: 0;
}

#chakra-modal-login-page-foget-password-modal .chakra-modal__body {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #333333;
}

#chakra-modal-login-page-foget-password-modal .chakra-modal__footer {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
